import { FollowType, notEmpty, PageType } from "@product/scmp-sdk";
import first from "lodash/first";
import type { ReactNode } from "react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { LinkHighlightArea } from "scmp-app/components/content/content-item-render/highlight/styles";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import { getContentSponsor } from "scmp-app/components/content/content-sponsor-tag/helpers";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { sectionTopLeadPrimaryContentItemContent$key } from "scmp-app/queries/__generated__/sectionTopLeadPrimaryContentItemContent.graphql";

import {
  ActionBar,
  Container,
  Headline,
  LeadingImage,
  Primary,
  Rest,
  Section,
  SectionContainer,
  StyledContentCoverImage,
  StyledEntityLink,
  StyledHomeFollowButton,
  SubHeadline,
  Summary,
  Topic,
  TopicContainer,
} from "./styles";

export type Props = {
  children?: ReactNode;
  reference: sectionTopLeadPrimaryContentItemContent$key;
  withBookmark?: boolean;
  withComment?: boolean;
  withSection?: boolean;
  withTopic?: boolean;
  withVideoOverlay?: boolean;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      className,
      customQueryParameters,
      onClick,
      reference: reference_,
      withBookmark = false,
      withComment = false,
      withSection = false,
      withTopic = false,
      withVideoOverlay = false,
    },
    reference,
  ) => {
    const content = useFragment(
      graphql`
        fragment sectionTopLeadPrimaryContentItemContent on Content {
          entityId
          urlAlias
          topics {
            ...followButtonBase
          }
          ...entityLink
          ...contentCoverImageContent @arguments(withLandscape250x99: true, withSize540x360: true)
          ...helpersUseContentSponsorContent
          ...hooksContentItemProvidersContent
            @arguments(
              withSections: true
              withTopicLink: true
              withHeadline: true
              withSummary: true
              withSubHeadline: true
              withActionBar: true
              withSponsorTag: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content, { type: "main" });

    const contentSponsor = getContentSponsor(content);
    const isSponsorArticle = notEmpty(contentSponsor?.name);
    const topic = first(content?.topics ?? []);
    const currentPageType = useCurrentPageType();

    return (
      <Container className={className} ref={reference}>
        <LeadingImage>
          <StyledEntityLink
            reference={content}
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
          >
            <StyledContentCoverImage
              reference={content}
              responsiveVariants={{
                desktopUp: "landscape250x99",
                mobileUp: "size540x360",
                tabletUp: "landscape250x99",
              }}
            />
            {withVideoOverlay &&
              providers.video?.durationOverlay({
                iconSize: "large",
                variant: "homepage",
              })}
          </StyledEntityLink>
        </LeadingImage>
        <Primary>
          {withTopic && (
            <TopicContainer>
              <Topic>{providers.topicLink?.()}</Topic>
              {topic && (
                <StyledHomeFollowButton
                  buttonVariant="square"
                  reference={topic}
                  source={currentPageType === PageType.Section ? "Section" : "HP Section Block"}
                  type={FollowType.Topic}
                />
              )}
            </TopicContainer>
          )}
          {withSection && (
            <SectionContainer>
              <Section>{providers.sections()}</Section>
            </SectionContainer>
          )}
          <StyledEntityLink
            query={customQueryParameters}
            reference={content}
            onClick={() => onClick?.(content.entityId, content.urlAlias)}
          >
            <LinkHighlightArea>
              <Headline>
                {providers.headline({
                  hideTooltip: true,
                  preferSocialHeadline: true,
                })}
              </Headline>
              <Summary>
                {providers.summary({
                  isTextOverflowEllipsis: true,
                })}
              </Summary>
              <SubHeadline>{providers.subHeadline()}</SubHeadline>
            </LinkHighlightArea>
          </StyledEntityLink>
          {isSponsorArticle ? (
            providers.sponsorTag?.({ variant: "home" })
          ) : (
            <ActionBar>
              {providers.actionBar({
                commentVariant: "reverse",
                isBookmarkVisible: withBookmark ?? false,
                isCommentsVisible: withComment ?? false,
              })}
            </ActionBar>
          )}
        </Primary>
        <Rest>{children}</Rest>
      </Container>
    );
  },
);

Component.displayName = "ContentItemSectionTopLeadPrimary";

export const ContentItemSectionTopLeadPrimary = withHighlightedHeadline(Component);
