import dynamic from "next/dynamic";

import type { Props as HomeFooterProps } from "scmp-app/components/home/footer";
import type { PlusFooterProps } from "scmp-app/components/plus/footer";

export const DynamicPostiesFooter = dynamic<Record<string, never>>(() =>
  import("scmp-app/components/posties/footer").then(module => module.PostiesFooter),
);

export const DynamicStyleFooter = dynamic<Record<string, never>>(() =>
  import("scmp-app/components/section/section-style/footer").then(module => module.StyleFooter),
);

export const DynamicHomeFooter = dynamic<HomeFooterProps>(() =>
  import("scmp-app/components/home/footer").then(module => module.HomeFooter),
);

export const DynamicPlusFooter = dynamic<PlusFooterProps>(() =>
  import("scmp-app/components/plus/footer").then(module => module.PlusFooter),
);
