import styled from "@emotion/styled";
import { type ComponentType, forwardRef } from "react";

import { Figure as CoverImageFigure } from "scmp-app/components/content/content-cover-image/styles";
import { Headline, SponsorHeadline } from "scmp-app/components/content/content-headline/styles";
import { Container as HomepageVideoDurationOverlayContainer } from "scmp-app/components/video/video-duration-overlay/variants/homepage/styles";

import { LinkHighlightArea } from "./styles";

export function withHighlightedHeadline<Props extends object>(Component: ComponentType<Props>) {
  const StyleComponent = styled(Component)`
    ${Headline}:hover, ${SponsorHeadline}:hover {
      color: #2c4692;
    }

    /* prettier-ignore */
    &:has(${CoverImageFigure}:hover, ${LinkHighlightArea}:hover, ${HomepageVideoDurationOverlayContainer}:hover) {
      ${Headline}:not([data-skip-highlight="true"]), ${SponsorHeadline}:not([data-skip-highlight="true"]) {
        color: #2c4692;
      }
    }
  `;

  const WrappedComponent = forwardRef<HTMLDivElement, Props>((props, reference) => (
    <StyleComponent {...props} ref={reference} />
  ));
  WrappedComponent.displayName = "WrappedComponent";

  return WrappedComponent;
}
